import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence } from 'framer-motion';

import WordmarkSrc from '@/assets/wordmark.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import {
  Actions,
  ActionText,
  BackButton,
  Body,
  Header,
  HeaderLogo,
  StyledPage,
  TextContainer,
} from './OnboardingTextPromptSelection.mobile.styles';
import { OnboardingTextPromptSelectionProps } from './OnboardingTextPromptSelection.types';

export function OnboardingTextPromptSelectionMobile({
  storytellerFirstName,
  selectedPromptsCount,
  onNext,
  onBack,
  PromptList,
}: OnboardingTextPromptSelectionProps) {
  return (
    <StyledPage>
      <Header>
        <BackButton icon={faChevronLeft} size="xl" color="var(--inverse-on-surface-secondary)" onClick={onBack} />
        <HeaderLogo src={WordmarkSrc} alt="Remento wordmark" />
      </Header>

      <Body>
        <TextContainer>
          <RMText type="serif" size="l" color="on-surface-primary">
            Select at least 3 questions
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary">
            These are the first prompts {storytellerFirstName} will receive.
          </RMText>
        </TextContainer>

        {PromptList}
        <RMSpacer spacing="4xl" direction="column" />
      </Body>

      <AnimatePresence>
        {selectedPromptsCount > 0 && (
          <Actions
            initial={{ translateY: '100%' }}
            animate={{ translateY: '0%', transition: { duration: 0.25, ease: 'easeOut' } }}
            exit={{ translateY: '100%', transition: { duration: 0.25, ease: 'easeIn' } }}
          >
            <ActionText type="sans" size="xs" bold color="on-surface-primary">
              {selectedPromptsCount} questions selected
            </ActionText>
            <RMButton background="primary" disabled={selectedPromptsCount < 3} fullWidth onClick={onNext}>
              Next
            </RMButton>
          </Actions>
        )}
      </AnimatePresence>
    </StyledPage>
  );
}
