import { useCallback, useEffect, useMemo, useState } from 'react';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { PromptType } from '@remento/types/project';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMStack } from '@/components/RMStack/RMStack';
import { useIsFormValid } from '@/modules/form/form';
import { getInputValue, setInputValue } from '@/modules/form/input';
import { PromptEdit } from '@/modules/project/components/PromptEdit/PromptEdit';
import { createPromptEditForm } from '@/modules/project/prompt-edit.form';
import {
  PromptDraftsStore,
  removePromptDraft,
  updatePromptDraftPhoto,
  updatePromptDraftQuestion,
  usePromptDraft,
} from '@/modules/project/states/prompt-drafts.state';
import { FilestackHandle } from '@/utils/filestack';

export interface OnboardingPromptEditContainerProps {
  promptDraftsStore: PromptDraftsStore;
  promptId: string | null;
  onClose: () => void;
}

// We cannot use the same container from the Upcoming Prompts page because this container only interacts
// with the prompt draft store instead of the prompt service.
export function OnboardingPromptEditContainer({
  promptDraftsStore,
  promptId,
  onClose,
}: OnboardingPromptEditContainerProps) {
  const prompt = usePromptDraft(promptDraftsStore, promptId ?? '');

  const form = useMemo(() => createPromptEditForm(), []);
  const isFormValid = useIsFormValid(form);

  const [photo, setPhoto] = useState<FilestackHandle | null>(null);

  const photoUrl = useMemo(() => {
    if (photo) {
      return photo.url;
    }
    if (prompt?.type === PromptType.PHOTO) {
      return prompt.photo.url;
    }
    return null;
  }, [photo, prompt]);

  // Save
  const handleSave = useCallback(() => {
    if (promptId === null) {
      return;
    }

    const question = getInputValue(form, 'question');
    if (question === null) {
      return;
    }

    updatePromptDraftQuestion(promptDraftsStore, promptId, question);

    if (photo !== null) {
      updatePromptDraftPhoto(promptDraftsStore, promptId, photo);
    }

    onClose();
  }, [form, onClose, photo, promptId, promptDraftsStore]);

  // Delete
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleAskDelete = useCallback(() => {
    setDeleteConfirmationOpen(true);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (promptId === null) {
      return;
    }

    removePromptDraft(promptDraftsStore, promptId);
    setDeleteConfirmationOpen(false);
    onClose();
  }, [onClose, promptId, promptDraftsStore]);

  const handleCancelDelete = useCallback(() => {
    setDeleteConfirmationOpen(false);
  }, []);

  // Update form state
  useEffect(() => {
    if (prompt !== null) {
      setInputValue(form, 'question', prompt?.question ?? '');
    }
  }, [prompt, form]);

  // Clear the photo when the prompt changes
  useEffect(() => {
    setPhoto(null);
  }, [promptId]);

  return (
    <RMDialog.Root open={promptId !== null} variant="full-screen" onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title="Edit prompt"
          centerTitle
          rightAdornment={
            <RMStack direction="row" spacing="xs">
              <RMIconButton
                icon={faTrashCan}
                tooltip={{ label: 'Remove', position: 'bottom' }}
                backgroundColor="transparent"
                stateColor="darken-neutral"
                onClick={handleAskDelete}
              />
              <RMCloseButton onClick={onClose} />
            </RMStack>
          }
        />

        <RMDialog.Body>
          <PromptEdit form={form} photoUrl={photoUrl} onPhotoChange={setPhoto} />
        </RMDialog.Body>

        <RMDialog.Footer>
          <RMButton background="primary" fullWidth autoLoading disabled={isFormValid === false} onClick={handleSave}>
            Save
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>

      <RMConfirmationModal
        open={deleteConfirmationOpen}
        title="Remove prompt"
        message="This action cannot be undone."
        type="danger"
        confirmLabel="Remove"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        onClose={handleCancelDelete}
      />
    </RMDialog.Root>
  );
}
