import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

import WordmarkSrc from '@/assets/wordmark.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { genitiveCase } from '@/utils/genitiveCase';

import {
  Actions,
  BackButton,
  Body,
  Header,
  HeaderLogo,
  StyledPage,
  TextContainer,
} from './OnboardingPromptsReview.mobile.styles';
import { OnboardingPromptsReviewProps } from './OnboardingPromptsReview.types';

export function OnboardingPromptsReviewMobile({
  storytellerFirstName,
  nextDisabled,
  showAddMore,
  onAddMore,
  onNext,
  onBack,
  PromptList,
}: OnboardingPromptsReviewProps) {
  return (
    <StyledPage>
      <Header>
        <BackButton icon={faChevronLeft} size="xl" color="var(--inverse-on-surface-secondary)" onClick={onBack} />
        <HeaderLogo src={WordmarkSrc} alt="Remento wordmark" />
      </Header>

      <Body>
        <TextContainer>
          <RMText type="serif" size="l" color="on-surface-primary">
            Review {genitiveCase(storytellerFirstName)} prompts
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary">
            Tap to edit, or drag to reorder. You can edit these later.
          </RMText>
        </TextContainer>

        {PromptList}
        <RMSpacer spacing="4xl" direction="column" />
      </Body>

      <Actions>
        {showAddMore && (
          <RMButton fullWidth onClick={onAddMore}>
            Add more
          </RMButton>
        )}
        <RMButton background="primary" fullWidth disabled={nextDisabled} onClick={onNext} autoLoading>
          Next
        </RMButton>
      </Actions>
    </StyledPage>
  );
}
