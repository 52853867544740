import { styled } from '@linaria/react';

export const StyledPage = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2xl) var(--spacing-3xl);
  border-bottom: 1px solid var(--border-hairline);

  > img {
    height: 1.5rem;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  width: 100%;
  max-width: 1512px;
  height: 100%;
  padding: 0 var(--spacing-5xl);
  padding-bottom: var(--spacing-2xl);
  margin: var(--spacing-2xl) auto 0;
  gap: var(--spacing-5xl);
  overflow: hidden;
`;

export const LeftPanel = styled.div`
  max-width: 400px;
  width: 100%;
  height: 100%;
`;

export const RightPanel = styled.div`
  width: 100%;
  min-width: 350px;
  height: 100%;
  flex: 1;
  overflow: auto;
  padding-top: var(--spacing-2xl);
`;
