import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';

import photoAnimation from '@/assets/photo-prompt.lottie.json';
import questionAnimation from '@/assets/question-prompt.lottie.json';
import WordmarkSrc from '@/assets/wordmark.svg';
import WordmarkPrimarySrc from '@/assets/wordmark-primary.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { AddPromptsSelectTypeCard } from '@/modules/project/components/AddPromptsSelectTypeCard/AddPromptsSelectTypeCard';

import {
  Body,
  Buttons,
  CardsContainer,
  CardsContainerText,
  Header,
  HeaderLogo,
  MobileHeader,
  StyledPage,
  TextContainer,
} from './OnboardingPromptTypeSelection.styles';

export interface OnboardingPromptTypeSelectionProps {
  onStartWithPhotos: () => void;
  onStartWithQuestions: (() => void) | null;
  onBack: () => void;
  onSkip: () => void;
}

export function OnboardingPromptTypeSelection({
  onStartWithPhotos,
  onStartWithQuestions,
  onBack,
  onSkip,
}: OnboardingPromptTypeSelectionProps) {
  const isMobile = useIsMobileViewport();

  return (
    <StyledPage>
      {isMobile ? (
        <MobileHeader>
          <HeaderLogo src={WordmarkSrc} alt="Remento wordmark" />
        </MobileHeader>
      ) : (
        <Header>
          <HeaderLogo src={WordmarkPrimarySrc} alt="Remento wordmark" />
        </Header>
      )}

      <Body>
        {!isMobile && (
          <Buttons>
            <RMButton leftIcon={faAngleLeft} background="none" onClick={onBack}>
              Back
            </RMButton>
            <RMButton rightIcon={faAngleRight} background="none" onClick={onSkip}>
              Skip
            </RMButton>
          </Buttons>
        )}
        <TextContainer>
          <RMText size={isMobile ? 'l' : 'xl'} type="serif" color="on-surface-primary">
            {onStartWithQuestions ? 'Add your first prompts' : 'Try creating your first story'}
          </RMText>
        </TextContainer>
        <CardsContainer>
          <AddPromptsSelectTypeCard
            title={onStartWithQuestions ? 'Start with photos' : 'Start with a photo'}
            text={
              onStartWithQuestions
                ? 'Upload photos to inspire stories.'
                : 'Upload a photo you want to add to your book.'
            }
            lottieSrc={photoAnimation}
            onClick={onStartWithPhotos}
          />

          {onStartWithQuestions && (
            <>
              {isMobile === false && (
                <CardsContainerText type="sans" size="s" bold color="on-surface-primary">
                  Or
                </CardsContainerText>
              )}

              <AddPromptsSelectTypeCard
                title="Start with questions"
                text="Choose from our bank of expert-crafted questions"
                lottieSrc={questionAnimation}
                onClick={onStartWithQuestions}
              />
            </>
          )}
        </CardsContainer>
      </Body>
    </StyledPage>
  );
}
