import { useCallback } from 'react';
import { ProjectStatus, PromptStatus, PromptType } from '@remento/types/project';

import TextPromptThumbnailSrc from '@/assets/prompt-thumbnail.svg';
import { PromptList } from '@/modules/project/components/PromptList';
import { movePromptDraft, usePromptDraft, usePromptDraftIds } from '@/modules/project/states/prompt-drafts.state';
import { usePromptDraftsStore } from '@/modules/project/states/prompt-drafts.state.context';

interface OnboardingPromptDraftListItemContainerProps {
  id: string;
  onClick: () => void;
  onMove: (id: string, direction: 'up' | 'down') => void;
}

function OnboardingPromptDraftListItemContainer({ id, onClick, onMove }: OnboardingPromptDraftListItemContainerProps) {
  const promptDraftsStore = usePromptDraftsStore();
  const prompt = usePromptDraft(promptDraftsStore, id);

  if (prompt === null) {
    return null;
  }

  return (
    <PromptList.Item
      id={id}
      image={prompt.type === PromptType.TEXT ? TextPromptThumbnailSrc : prompt.photo.url}
      title={prompt.question}
      timestamp={0}
      projectStatus={ProjectStatus.PAUSED}
      status={PromptStatus.PENDING}
      hideActions
      onClick={onClick}
      onAction={() => null}
      onMove={onMove}
    />
  );
}

export interface OnboardingPromptDraftListContainerProps {
  onEdit: (promptId: string) => void;
}

export function OnboardingPromptDraftListContainer({ onEdit }: OnboardingPromptDraftListContainerProps) {
  const promptDraftsStore = usePromptDraftsStore();
  const promptDraftsIds = usePromptDraftIds(promptDraftsStore);

  const handleMoveToIndex = useCallback(
    async (promptId: string, newIndex: number) => {
      movePromptDraft(promptDraftsStore, promptId, newIndex);
    },
    [promptDraftsStore],
  );

  const handleMoveToId = useCallback(
    async (draggedPromptId: string, droppedPromptId: string) => {
      const index = promptDraftsIds.indexOf(droppedPromptId);
      if (index === -1) {
        return;
      }

      handleMoveToIndex(draggedPromptId, index);
    },
    [handleMoveToIndex, promptDraftsIds],
  );

  const handleMoveToDirection = useCallback(
    async (draggedPromptId: string, direction: 'up' | 'down') => {
      const index = promptDraftsIds.indexOf(draggedPromptId);
      if (index === -1) {
        return;
      }

      handleMoveToIndex(draggedPromptId, direction === 'up' ? index - 1 : index + 1);
    },
    [promptDraftsIds, handleMoveToIndex],
  );

  return (
    <PromptList.Root items={promptDraftsIds} onMove={handleMoveToId}>
      {promptDraftsIds.map((promptId) => (
        <OnboardingPromptDraftListItemContainer
          key={promptId}
          id={promptId}
          onClick={() => onEdit(promptId)}
          onMove={handleMoveToDirection}
        />
      ))}
    </PromptList.Root>
  );
}
