import { useCallback } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { PromptType } from '@remento/types/project';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { OnboardingTextPromptSelection } from '@/modules/onboarding/components/OnboardingTextPromptSelection/OnboardingTextPromptSelection';
import { OnboardingTextPromptSelectionMobile } from '@/modules/onboarding/components/OnboardingTextPromptSelection/OnboardingTextPromptSelection.mobile';
import { OnboardingPromptTemplateListContainer } from '@/modules/onboarding/containers/OnboardingPromptTemplateList.container';
import { useOnboardingContext } from '@/modules/onboarding/onboarding.context';
import { usePromptDraftsCount } from '@/modules/project/states/prompt-drafts.state';
import { usePromptDraftsStore } from '@/modules/project/states/prompt-drafts.state.context';
import { getSetupPromptsReviewPath, getSetupPromptTypeSelectionPath } from '@/modules/routing';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

export function SetupTextPromptSelectionPage() {
  const projectId = useParams().projectId ?? '';
  const isMobile = useIsMobileViewport();
  const navigate = useNavigate();
  const user = useUser();

  const promptDraftsStore = usePromptDraftsStore();
  const selectedPromptsCount = usePromptDraftsCount(promptDraftsStore);
  const { promptsType } = useOnboardingContext();
  const projectQuery = useProjectQuery(projectId);
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const storytellerFirstName =
    user?.personId === projectQuery.data?.subjectPersonIds[0] ? 'you' : storytellerPersonQuery.data?.name?.first ?? '';

  const handleNext = useCallback(() => {
    navigate(getSetupPromptsReviewPath(projectId));
  }, [navigate, projectId]);

  const handleGoBack = useCallback(async () => {
    navigate(getSetupPromptTypeSelectionPath(projectId));
  }, [navigate, projectId]);

  if (promptsType !== PromptType.TEXT) {
    return <Navigate to={getSetupPromptTypeSelectionPath(projectId)} />;
  }

  if (isMobile) {
    return (
      <OnboardingTextPromptSelectionMobile
        storytellerFirstName={storytellerFirstName}
        selectedPromptsCount={selectedPromptsCount}
        onNext={handleNext}
        onBack={handleGoBack}
        PromptList={<OnboardingPromptTemplateListContainer promptDraftsStore={promptDraftsStore} />}
      />
    );
  }

  return (
    <OnboardingTextPromptSelection
      storytellerFirstName={storytellerFirstName}
      selectedPromptsCount={selectedPromptsCount}
      onNext={handleNext}
      onBack={handleGoBack}
      PromptList={<OnboardingPromptTemplateListContainer promptDraftsStore={promptDraftsStore} />}
    />
  );
}
