import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMInput } from '@/components/RMInput';

export const StyledPage = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100dvh;
  align-items: center;

  body[data-mobile='true'] & {
    overflow-y: auto;
    position: relative;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2xl) var(--spacing-3xl);
  border-bottom: 1px solid var(--border-hairline);
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--inverse-surface);
  width: 100%;
  height: 56px;
  flex: 0 0 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-md);
`;

export const BackButton = styled(FontAwesomeIcon)`
  margin-right: auto;
`;

export const HeaderLogo = styled.img`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
    position: absolute;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
  padding-left: var(--spacing-5xl);
  max-width: 1512px;
  margin: 0 auto;
  overflow: hidden;

  body[data-mobile='true'] & {
    padding-left: 0;
    margin: 0;
    max-width: unset;
  }
`;

export const LeftPanel = styled.div`
  flex: 1;
  height: 100%;
  padding: var(--spacing-2xl) var(--spacing-3xl) var(--spacing-2xl) 0;
  margin-right: var(--spacing-3xl);
  overflow: auto;

  body[data-mobile='true'] & {
    padding: var(--spacing-xl) var(--spacing-md) var(--spacing-6xl) var(--spacing-md);
    margin: 0;
  }
`;

export const RightPanel = styled.div`
  flex: 1;
  min-width: 350px;
  max-width: 756px;
  height: 100%;
  background-color: var(--color-spruce-40);
`;

export const RightPanelImage = styled.img`
  height: 100%;
  width: 80%;
  transform: scale(1.2) translateY(20%) translateX(10%);
  object-position: top;
  object-fit: contain;
`;

export const EmailFormsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const InputButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputButtonGroupInput = styled(RMInput)`
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const InputButtonGroupButton = styled(RMButton)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top: 1px solid var(--border-strong) !important;
  border-right: 1px solid var(--border-strong) !important;
  border-bottom: 1px solid var(--border-strong) !important;
  border-left: none !important;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-md);
  width: 100%;
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xl) 0;
`;

export const SeparatorLine = styled.div`
  height: 1px;
  flex: 1;
  background-color: var(--border-hairline);
`;

export const MobileFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  border-top: 1px solid var(--border-hairline);
  background-color: var(--surface);
  z-index: 1;
`;
