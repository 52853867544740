import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StoryPerspectiveType } from '@remento/types/story';

import { toast } from '@/components/RMToast/RMToast.tsx';
import { OnboardingPerspectiveSelection } from '@/modules/onboarding/components/OnboardingPerspectiveSelection/OnboardingPerspectiveSelection';
import { getSetupCollaboratorsPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook.tsx';
import { EntityMutation } from '@/services/api/cache';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException.ts';

export function SetupPerspectivePage() {
  const projectId = useParams().projectId ?? '';

  const projectQuery = useProjectQuery(projectId);
  const { projectService, entityCacheManagerService, projectAnalyticsService } = useServices();
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const storytellerFirstName = storytellerPersonQuery.data?.name?.first ?? '';
  const navigate = useNavigate();

  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);

  // State
  const [perspective, setPerspective] = useState(StoryPerspectiveType.THIRD_PERSON);

  // Actions
  const handleFinish = useCallback(async () => {
    try {
      if (projectId == null || projectQuery.data == null) {
        throw new Error('Invalid project');
      }

      const project = projectQuery.data;
      const mutations: EntityMutation[] = [];

      mutations.push(...projectService.createSetDefaultStoryPerspectiveMutation(project, perspective));

      // Run mutations
      await entityCacheManagerService.mutate(mutations);

      // Analytics
      projectAnalyticsService.onProjectDefaultPerspectiveChanged(perspective, 'onboarding');

      navigate(getSetupCollaboratorsPath(projectId));
    } catch (err) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(err, true);
    }
  }, [
    entityCacheManagerService,
    navigate,
    perspective,
    projectAnalyticsService,
    projectId,
    projectQuery.data,
    projectService,
  ]);

  useEffect(() => {
    if (projectQuery.data == null) {
      return;
    }

    setPerspective(projectQuery.data.configuration.defaultStoryPerspective);
  }, [projectQuery.data]);

  return (
    <OnboardingPerspectiveSelection
      currentPerspective={perspective}
      storytellerName={storytellerFirstName}
      userIsStoryteller={
        projectQuery.data?.notifications.recipientPersonIds.some((personId) =>
          personQuery.data?.refIds.includes(personId),
        ) ?? false
      }
      onChangePerspective={setPerspective}
      onFinish={handleFinish}
    />
  );
}
