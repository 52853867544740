import { PropsWithChildren } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '../RMText/RMText';

import { Header, StyledRMCardPickerItem } from './RMCardPickerItem.styles';

export type RMCardPickerItemProps = PropsWithChildren<{
  title: string;
  icon?: IconDefinition | null;
  message: string;
  selected: boolean;
  onClick: () => void;
}>;

export function RMCardPickerItem({ title, icon, message, selected, onClick, children }: RMCardPickerItemProps) {
  return (
    <StyledRMCardPickerItem data-selected={selected} onClick={onClick}>
      <Header>
        <RMText size="s" type="sans" bold color="on-surface-primary">
          {title}
        </RMText>
        {icon && <FontAwesomeIcon icon={icon} color="var(--primary)" />}
      </Header>
      <RMText size="s" type="sans" color="on-surface-primary" lineHeight="s">
        {message}
      </RMText>
      {children}
    </StyledRMCardPickerItem>
  );
}
