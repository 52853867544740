import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { StoryPerspectiveType } from '@remento/types/story';

import WordmarkSrc from '@/assets/wordmark.svg';
import WordmarkPrimarySrc from '@/assets/wordmark-primary.svg';
import { RMBackButton } from '@/components/RMBackButton';
import { RMCardPicker } from '@/components/RMCardPicker';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { genitiveCase } from '@/utils/genitiveCase';

import {
  Body,
  Button,
  Header,
  HeaderLogo,
  HiddenBackButton,
  LeftPanel,
  MobileFooter,
  MobileHeader,
  RightPanel,
  StyledPage,
} from './OnboardingPerspectiveSelection.styles';

interface OnboardingPerspectiveSelection {
  storytellerName: string;
  userIsStoryteller: boolean;
  currentPerspective: StoryPerspectiveType;
  onChangePerspective: (perspective: StoryPerspectiveType) => void;
  onFinish: () => void;
}

interface PerspectiveItem {
  label: string;
  value: StoryPerspectiveType;
  message: string;
  showIcon?: boolean;
  example?: string;
}

const perspectives: PerspectiveItem[] = [
  {
    label: 'Third person story',
    message:
      'Our AI Writing Assistant generates a story from the original transcription, told from the third-person perspective',
    value: StoryPerspectiveType.THIRD_PERSON,
    showIcon: true,
    example: 'Example: Claudette went to the store...',
  },
  {
    label: 'First person story',
    message:
      'Our AI Writing Assistant generates a story from the original transcription, told from the first-person perspective',
    value: StoryPerspectiveType.FIRST_PERSON,
    showIcon: true,
    example: 'Example: I went to the store...',
  },
  {
    label: 'Cleaned transcript',
    message: 'The original transcription, with filler words like “um” and “ah” removed.',
    value: StoryPerspectiveType.TRANSCRIPT,
  },
];

export function OnboardingPerspectiveSelection({
  storytellerName,
  userIsStoryteller,
  currentPerspective,
  onChangePerspective,
  onFinish,
}: OnboardingPerspectiveSelection) {
  const isMobile = useIsMobileViewport();

  return (
    <StyledPage>
      {isMobile ? (
        <MobileHeader>
          <HeaderLogo src={WordmarkSrc} alt="Remento wordmark" />
        </MobileHeader>
      ) : (
        <Header>
          <HeaderLogo src={WordmarkPrimarySrc} alt="Remento wordmark" />
        </Header>
      )}

      <Body>
        <LeftPanel>
          {isMobile == false && (
            // This is used to avoid shifting the layout when entering this step.
            <HiddenBackButton>
              <RMBackButton />
              <RMSpacer direction="column" spacing="2xl" />
            </HiddenBackButton>
          )}
          <RMText align="center" size={isMobile ? 'l' : 'xl'} type="serif" color="on-surface-primary">
            How would you like {userIsStoryteller ? 'your' : genitiveCase(storytellerName)} stories transcribed?
          </RMText>
          <RMSpacer direction="column" spacing={isMobile ? 'sm' : 'xl'} />
          <RMText align={isMobile ? 'center' : 'left'} size="s" type="sans" color="on-surface-primary" lineHeight="s">
            Remento’s Speech-To-Story™ technology turns spoken words into written stories. You have full control over
            how your stories are transcribed. This default setting can be changed at any time.
          </RMText>

          {isMobile == false && (
            <>
              <RMSpacer direction="column" spacing="2xl" />
              <Button background="primary" autoLoading onClick={onFinish}>
                Next
              </Button>
            </>
          )}
        </LeftPanel>
        <RightPanel>
          <RMSpacer direction="column" spacing="2xl" />
          <RMCardPicker.Root>
            {perspectives.map(({ value, label, message, showIcon, example }) => (
              <RMCardPicker.Item
                key={value}
                title={label}
                icon={showIcon ? faSparkles : null}
                message={message}
                selected={currentPerspective === value}
                onClick={() => {
                  onChangePerspective(value);
                }}
              >
                {example && (
                  <RMText size="s" type="sans" color="on-surface-primary" lineHeight="s">
                    <i>{example}</i>
                  </RMText>
                )}
              </RMCardPicker.Item>
            ))}
          </RMCardPicker.Root>
        </RightPanel>
      </Body>

      {isMobile && (
        <MobileFooter>
          <Button background="primary" autoLoading fullWidth onClick={onFinish}>
            Next
          </Button>
        </MobileFooter>
      )}
    </StyledPage>
  );
}
