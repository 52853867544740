import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMText } from '@/components/RMText/RMText';

export const StyledPage = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--inverse-surface);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-md);
`;

export const BackButton = styled(FontAwesomeIcon)`
  margin-right: auto;
`;

export const HeaderLogo = styled.img`
  height: 1rem;
  position: absolute;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-xl) var(--spacing-md) var(--spacing-md) var(--spacing-md);
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const Actions = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  padding: var(--spacing-sm) var(--spacing-md);
  border-top: 1px solid var(--border-hairline);
  background-color: var(--surface);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const ActionText = styled(RMText)`
  width: fit-content;
  min-width: 50%;
`;
