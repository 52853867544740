import { useEffect, useState } from 'react';
import EmailValidator from 'email-validator';
import { z } from 'zod';

import { Form, InputValidationState } from '@/modules/form/@types';
import { createForm, isFormValid, subscribeIsFormValid } from '@/modules/form/form';
import { getInputValidation, getInputValue, watchInputValidation } from '@/modules/form/input';

const emailFormSchema = z.object({
  email: z.string().refine(
    (email) => {
      if (email.trim().length == 0) {
        return true;
      }
      return EmailValidator.validate(email);
    },
    { message: 'Enter a valid email address' },
  ),
});

export type EmailFormInput = z.input<typeof emailFormSchema>;
export type EmailFormOutput = z.output<typeof emailFormSchema>;
export type EmailForm = Form<EmailFormInput, EmailFormOutput>;

export function createEmailForm() {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues: { email: '' },
    schema: emailFormSchema,
  });
}

export interface IdentifiableEmailForm {
  id: string;
  form: EmailForm;
}

export function useIncludesAtLeastOneEmail(emailForms: IdentifiableEmailForm[]): boolean {
  const [includesAtLeastOneEmail, setIncludesAtLeastOneEmail] = useState(false);

  useEffect(() => {
    const validForms = new Set<string>();

    const checkValidation = (emailForm: IdentifiableEmailForm, validation: InputValidationState) => {
      const email = getInputValue(emailForm.form, 'email');
      if (email != null && email.trim().length > 0 && validation.isValid) {
        validForms.add(emailForm.id);
      } else {
        validForms.delete(emailForm.id);
      }

      setIncludesAtLeastOneEmail(validForms.size > 0);
    };

    const unsubscribeFunctions = emailForms.map((emailForm) => {
      checkValidation(emailForm, getInputValidation(emailForm.form, 'email'));

      return watchInputValidation(emailForm.form, 'email', (validation) => {
        checkValidation(emailForm, validation);
      });
    });

    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }, [emailForms]);

  return includesAtLeastOneEmail;
}

export function useAreAllFormsValid(emailForms: IdentifiableEmailForm[]): boolean {
  const [allFormsValid, setAllFormsValid] = useState(false);

  useEffect(() => {
    const validateForms = () => {
      setAllFormsValid(emailForms.every((emailForm) => isFormValid(emailForm.form)));
    };

    const unsubscribeFunctions = emailForms.map((emailForm) => subscribeIsFormValid(emailForm.form, validateForms));

    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }, [emailForms]);

  return allFormsValid;
}
