import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PromptType } from '@remento/types/project';

import { OnboardingPromptTypeSelection } from '@/modules/onboarding/components/OnboardingPromptTypeSelection/OnboardingPromptTypeSelection';
import { useOnboardingContext } from '@/modules/onboarding/onboarding.context';
import { addPromptDraft } from '@/modules/project/states/prompt-drafts.state';
import { usePromptDraftsStore } from '@/modules/project/states/prompt-drafts.state.context';
import {
  getOnboardingIntroPath,
  getSetupPerspectivePath,
  getSetupPromptsReviewPath,
  getSetupTextPromptSelectionPath,
} from '@/modules/routing';
import { useServices } from '@/Services';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { openFilestackPicker } from '@/utils/filestack';
import { secureUuid } from '@/utils/uuid';

export function SetupPromptTypeSelectionPage() {
  const projectId = useParams().projectId ?? null;
  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;
  const { onboardingAnalyticsService, redirectService } = useServices();
  const navigate = useNavigate();

  const promptDraftsStore = usePromptDraftsStore();
  const { setPromptsType } = useOnboardingContext();

  const handleStartWithPhotos = useCallback(async () => {
    try {
      await openFilestackPicker({
        accept: ['image/png', 'image/jpeg', 'image/webp'],
        maxSize: 1024 * 1024 * 20,
        maxFiles: 20,
        onUploadDone: ({ filesUploaded }) => {
          for (const photo of filesUploaded) {
            addPromptDraft(promptDraftsStore, {
              id: secureUuid(),
              type: PromptType.PHOTO,
              photo,
              question: 'What is happening in this photo?',
              edited: false,
            });
          }

          setPromptsType(PromptType.PHOTO);
          navigate(projectId != null ? getSetupPromptsReviewPath(projectId) : '/');
          onboardingAnalyticsService.onOnboardingPromptTypeSelected(PromptType.PHOTO);
        },
      });
    } catch (error) {
      captureException(error, true);
    }
  }, [navigate, onboardingAnalyticsService, promptDraftsStore, setPromptsType, projectId]);

  const handleStartWithQuestions = useCallback(() => {
    setPromptsType(PromptType.TEXT);
    navigate(projectId != null ? getSetupTextPromptSelectionPath(projectId) : '/');
    onboardingAnalyticsService.onOnboardingPromptTypeSelected(PromptType.TEXT);
  }, [navigate, onboardingAnalyticsService, setPromptsType, projectId]);

  const handleBack = useCallback(async () => {
    await redirectService.registerRedirect('user-onboarded', window.location.pathname + window.location.search);
    navigate(getOnboardingIntroPath());
  }, [navigate, redirectService]);

  const handleSkip = useCallback(() => {
    navigate(projectId ? getSetupPerspectivePath(projectId) : '/');
  }, [navigate, projectId]);
  const hasTopics = project?.configuration.topics && project?.configuration.topics.length > 0;

  return (
    <OnboardingPromptTypeSelection
      onStartWithPhotos={handleStartWithPhotos}
      onStartWithQuestions={hasTopics ? handleStartWithQuestions : null}
      onBack={handleBack}
      onSkip={handleSkip}
    />
  );
}
