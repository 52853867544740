import { PromptTemplateList } from '@/modules/project/components/PromptTemplateList';
import { PromptTemplateListItemContainer } from '@/modules/project/containers/PromptTemplateList.container';
import { PromptDraftsStore } from '@/modules/project/states/prompt-drafts.state';
import { usePurchaserOnboardingPromptTemplatesQuery } from '@/services/cms/prompt-template/prompt-template.service.hook';

interface OnboardingPromptTemplateListContainerProps {
  promptDraftsStore: PromptDraftsStore;
}

export function OnboardingPromptTemplateListContainer({
  promptDraftsStore,
}: OnboardingPromptTemplateListContainerProps) {
  const { data } = usePurchaserOnboardingPromptTemplatesQuery();

  return (
    <PromptTemplateList.Root>
      {data?.map((prompt) => (
        <PromptTemplateListItemContainer
          key={prompt.id}
          prompt={prompt}
          trending={false}
          promptDraftsStore={promptDraftsStore}
        />
      ))}
    </PromptTemplateList.Root>
  );
}
