import { styled } from '@linaria/react';

export const StyledRMCardPickerItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-xl);
  border-radius: var(--radius-round);
  background-color: var(--surface-bright);
  border: 2px solid var(--border-primary);
  white-space: preserve;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;

  &[data-selected='false'] {
    background-color: transparent;
    border: 1px solid var(--border-strong);
    margin: 1px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
