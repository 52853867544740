import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';

export const StyledPage = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: center;

  body[data-mobile='true'] & {
    overflow-y: auto;
    max-height: 100dvh;
    position: relative;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2xl) var(--spacing-3xl);
  border-bottom: 1px solid var(--border-hairline);
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--inverse-surface);
  width: 100%;
  height: 56px;
  flex: 0 0 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-md);
`;

export const HeaderLogo = styled.img`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
    position: absolute;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: 1512px;
  height: 100%;
  padding: 0 var(--spacing-5xl);
  padding-bottom: var(--spacing-2xl);
  margin: var(--spacing-2xl) auto 0;
  overflow: hidden;

  body[data-mobile='true'] & {
    flex-direction: column;
    padding: var(--spacing-xl) var(--spacing-md) var(--spacing-6xl) var(--spacing-md);
    margin: 0;
    max-width: unset;
    overflow: auto;
  }
`;

export const LeftPanel = styled.div`
  max-width: 400px;
  width: 100%;
  height: 100%;

  body[data-mobile='true'] & {
    max-width: unset;
  }
`;

export const RightPanel = styled.div`
  width: 100%;
  min-width: 350px;
  height: 100%;
  flex: 1;

  body[data-mobile='false'] & {
    overflow: auto;
    padding-top: var(--spacing-2xl);
    padding-left: var(--spacing-5xl);
  }
`;

export const Button = styled(RMButton)`
  min-width: 8rem;
`;

export const MobileFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  border-top: 1px solid var(--border-hairline);
  background-color: var(--surface);
`;

export const HiddenBackButton = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
`;
