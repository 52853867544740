import WordmarkSrc from '@/assets/wordmark-primary.svg';
import { RMBackButton } from '@/components/RMBackButton';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import { Body, Header, LeftPanel, RightPanel, StyledPage } from './OnboardingTextPromptSelection.styles';
import { OnboardingTextPromptSelectionProps } from './OnboardingTextPromptSelection.types';

export function OnboardingTextPromptSelection({
  storytellerFirstName,
  selectedPromptsCount,
  onNext,
  onBack,
  PromptList,
}: OnboardingTextPromptSelectionProps) {
  return (
    <StyledPage>
      <Header>
        <img src={WordmarkSrc} alt="Remento wordmark" />
      </Header>

      <Body>
        <LeftPanel>
          <RMBackButton onClick={onBack} />
          <RMSpacer spacing="2xl" direction="column" />
          <RMText type="serif" size="xl" color="on-surface-primary">
            Select at least 3 prompts
          </RMText>
          <RMSpacer spacing="xl" direction="column" />
          <RMText type="sans" size="s" color="on-surface-primary">
            These are the first prompts {storytellerFirstName} will receive.
          </RMText>
          <RMSpacer spacing="xl" direction="column" />
          <RMText type="sans" size="s" bold color="primary-decorative">
            {selectedPromptsCount} prompts selected
          </RMText>
          <RMSpacer spacing="2xl" direction="column" />
          <RMButton background="primary" disabled={selectedPromptsCount < 3} onClick={onNext}>
            Next
          </RMButton>
        </LeftPanel>
        <RightPanel>{PromptList}</RightPanel>
      </Body>
    </StyledPage>
  );
}
